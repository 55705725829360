import React from "react"
import RacepageFS from "../components/racepageFS"

const NokereKoerse2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Danilith Nokere Koerse",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-17 13:12:59",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21797: {
        teamId: 21797,
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
        teamNationName: "Norway",
      },
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21849: {
        teamId: 21849,
        teamUciCode: "BBK",
        teamName: "B&B Hotels p/b KTM",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21851: {
        teamId: 21851,
        teamUciCode: "BCF",
        teamName: "Bardiani CSF Faizan\u00e8",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      21852: {
        teamId: 21852,
        teamUciCode: "BWB",
        teamName: "Bingoal-WB",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21859: {
        teamId: 21859,
        teamUciCode: "DKO",
        teamName: "Delko",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21861: {
        teamId: 21861,
        teamUciCode: "RLY",
        teamName: "Rally Cycling",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21863: {
        teamId: 21863,
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21867: {
        teamId: 21867,
        teamUciCode: "TDE",
        teamName: "Total Direct Energie",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21869: {
        teamId: 21869,
        teamUciCode: "THR",
        teamName: "Vini Zab\u00fa",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
      23305: {
        teamId: 23305,
        teamUciCode: "TIS",
        teamName: "Tarteletto - Isorex",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
    },
    riders: {
      1: {
        id: 14,
        startno: 1,
        firstName: "Mark",
        lastName: "Cavendish",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      2: {
        id: 9932,
        startno: 2,
        firstName: "Shane",
        lastName: "Archbold",
        nationCode: "NZL",
        nationName: "New Zealand",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      3: {
        id: 10103,
        startno: 3,
        firstName: "Josef",
        lastName: "Cern\u00fd",
        nationCode: "CZE",
        nationName: "Czech Republic",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      4: {
        id: 54278,
        startno: 4,
        firstName: "Ian",
        lastName: "Garrison",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      5: {
        id: 1298,
        startno: 5,
        firstName: "Iljo",
        lastName: "Keisse",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      6: {
        id: 16457,
        startno: 6,
        firstName: "Stijn",
        lastName: "Steels",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      7: {
        id: 47830,
        startno: 7,
        firstName: "Jannik",
        lastName: "Steimle",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      11: {
        id: 17210,
        startno: 11,
        firstName: "Marc",
        lastName: "Sarreau",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      12: {
        id: 14742,
        startno: 12,
        firstName: "Julien",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      13: {
        id: 12800,
        startno: 13,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      14: {
        id: 5685,
        startno: 14,
        firstName: "Anthony",
        lastName: "Jullien",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      15: {
        id: 7580,
        startno: 15,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      16: {
        id: 39481,
        startno: 16,
        firstName: "Nicolas",
        lastName: "Prodhomme",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      21: {
        id: 16694,
        startno: 21,
        firstName: "Erik",
        lastName: "Baska",
        nationCode: "SVK",
        nationName: "Slovakia",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      22: {
        id: 37439,
        startno: 22,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      23: {
        id: 16506,
        startno: 23,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      24: {
        id: 45364,
        startno: 24,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      25: {
        id: 8388,
        startno: 25,
        firstName: "Lukas",
        lastName: "P\u00f6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      26: {
        id: 2172,
        startno: 26,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      27: {
        id: 45352,
        startno: 27,
        firstName: "Matthew",
        lastName: "Walls",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      31: {
        id: 27331,
        startno: 31,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21807,
        teamName: "Cofidis",
      },
      32: {
        id: 71208,
        startno: 32,
        firstName: "Thomas",
        lastName: "Champion",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21807,
        teamName: "Cofidis",
      },
      33: {
        id: 37286,
        startno: 33,
        firstName: "Andr\u00e9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21807,
        teamName: "Cofidis",
      },
      34: {
        id: 47307,
        startno: 34,
        firstName: "Emmanuel",
        lastName: "Morin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21807,
        teamName: "Cofidis",
      },
      35: {
        id: 37294,
        startno: 35,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21807,
        teamName: "Cofidis",
      },
      37: {
        id: 1276,
        startno: 37,
        firstName: "Jelle",
        lastName: "Wallays",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21807,
        teamName: "Cofidis",
      },
      41: {
        id: 65036,
        startno: 41,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      42: {
        id: 45620,
        startno: 42,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      43: {
        id: 37397,
        startno: 43,
        firstName: "Cl\u00e9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      44: {
        id: 6260,
        startno: 44,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      45: {
        id: 38923,
        startno: 45,
        firstName: "Simon",
        lastName: "Guglielmi",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      46: {
        id: 10871,
        startno: 46,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      47: {
        id: 12493,
        startno: 47,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      51: {
        id: 41249,
        startno: 51,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      53: {
        id: 15686,
        startno: 53,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      54: {
        id: 45349,
        startno: 54,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      55: {
        id: 926,
        startno: 55,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      56: {
        id: 1867,
        startno: 56,
        firstName: "Cameron",
        lastName: "Wurf",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      61: {
        id: 10948,
        startno: 61,
        firstName: "Ludwig",
        lastName: "De Winter",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      62: {
        id: 68393,
        startno: 62,
        firstName: "Th\u00e9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      63: {
        id: 39185,
        startno: 63,
        firstName: "Jasper",
        lastName: "De Plus",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      64: {
        id: 7723,
        startno: 64,
        firstName: "Wesley",
        lastName: "Kreder",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      65: {
        id: 3187,
        startno: 65,
        firstName: "Boy",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      66: {
        id: 7730,
        startno: 66,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      67: {
        id: 33307,
        startno: 67,
        firstName: "Riccardo",
        lastName: "Minali",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      71: {
        id: 1581,
        startno: 71,
        firstName: "Sep",
        lastName: "Vanmarcke",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      72: {
        id: 14902,
        startno: 72,
        firstName: "Rudy",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      73: {
        id: 90911,
        startno: 73,
        firstName: "Taj",
        lastName: "Jones",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      74: {
        id: 70759,
        startno: 74,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      75: {
        id: 16963,
        startno: 75,
        firstName: "Guy",
        lastName: "Sagiv",
        nationCode: "ISR",
        nationName: "Israel",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      76: {
        id: 37384,
        startno: 76,
        firstName: "Norman",
        lastName: "Vahtra",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      77: {
        id: 7579,
        startno: 77,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      81: {
        id: 46012,
        startno: 81,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      82: {
        id: 7998,
        startno: 82,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      83: {
        id: 63035,
        startno: 83,
        firstName: "S\u00e9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      84: {
        id: 51348,
        startno: 84,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      85: {
        id: 44622,
        startno: 85,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      86: {
        id: 47124,
        startno: 86,
        firstName: "Gerben",
        lastName: "Thijssen",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      87: {
        id: 77143,
        startno: 87,
        firstName: "Xandres",
        lastName: "Vervloesem",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      91: {
        id: 71333,
        startno: 91,
        firstName: "Alberto",
        lastName: "Dainese",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21832,
        teamName: "Team DSM",
      },
      92: {
        id: 78129,
        startno: 92,
        firstName: "Thymen",
        lastName: "Arensman",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21832,
        teamName: "Team DSM",
      },
      93: {
        id: 66973,
        startno: 93,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21832,
        teamName: "Team DSM",
      },
      94: {
        id: 64651,
        startno: 94,
        firstName: "Tim",
        lastName: "Naberman",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21832,
        teamName: "Team DSM",
      },
      95: {
        id: 93288,
        startno: 95,
        firstName: "Casper",
        lastName: "Van Uden",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21832,
        teamName: "Team DSM",
      },
      96: {
        id: 76474,
        startno: 96,
        firstName: "Ilan",
        lastName: "Van Wilder",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21832,
        teamName: "Team DSM",
      },
      97: {
        id: 82912,
        startno: 97,
        firstName: "Kevin",
        lastName: "Vermaerke",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21832,
        teamName: "Team DSM",
      },
      101: {
        id: 9210,
        startno: 101,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      102: {
        id: 3118,
        startno: 102,
        firstName: "Matteo",
        lastName: "Pelucchi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      103: {
        id: 6100,
        startno: 103,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      104: {
        id: 6559,
        startno: 104,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      105: {
        id: 45419,
        startno: 105,
        firstName: "Andreas",
        lastName: "Stokbro",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      106: {
        id: 41208,
        startno: 106,
        firstName: "Dylan",
        lastName: "Sunderland",
        nationCode: "AUS",
        nationName: "Australia",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      107: {
        id: 65304,
        startno: 107,
        firstName: "Antonio",
        lastName: "Puppio",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      111: {
        id: 10382,
        startno: 111,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      112: {
        id: 191,
        startno: 112,
        firstName: "Koen",
        lastName: "De Kort",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      113: {
        id: 52664,
        startno: 113,
        firstName: "Jakob",
        lastName: "Egholm",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      114: {
        id: 7572,
        startno: 114,
        firstName: "Alexander",
        lastName: "Kamp",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      115: {
        id: 8740,
        startno: 115,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      121: {
        id: 66265,
        startno: 121,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      122: {
        id: 407,
        startno: 122,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      123: {
        id: 390,
        startno: 123,
        firstName: "Marco",
        lastName: "Marcato",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      124: {
        id: 34624,
        startno: 124,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      125: {
        id: 14325,
        startno: 125,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      126: {
        id: 16587,
        startno: 126,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      131: {
        id: 45363,
        startno: 131,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      132: {
        id: 65447,
        startno: 132,
        firstName: "Tobias",
        lastName: "Bayer",
        nationCode: "AUT",
        nationName: "Austria",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      133: {
        id: 7657,
        startno: 133,
        firstName: "Roy",
        lastName: "Jans",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      134: {
        id: 32571,
        startno: 134,
        firstName: "Edward",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      135: {
        id: 4779,
        startno: 135,
        firstName: "Oscar",
        lastName: "Riesebeek",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      136: {
        id: 57069,
        startno: 136,
        firstName: "Alexandar",
        lastName: "Richardson",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      137: {
        id: 20832,
        startno: 137,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      141: {
        id: 10950,
        startno: 141,
        firstName: "Frederik",
        lastName: "Backaert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      142: {
        id: 29100,
        startno: 142,
        firstName: "Thibault",
        lastName: "Ferasse",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      143: {
        id: 939,
        startno: 143,
        firstName: "Bert",
        lastName: "De Backer",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      144: {
        id: 1966,
        startno: 144,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      145: {
        id: 16143,
        startno: 145,
        firstName: "Julien",
        lastName: "Morice",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      146: {
        id: 46186,
        startno: 146,
        firstName: "Luca",
        lastName: "Mozzato",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      147: {
        id: 5297,
        startno: 147,
        firstName: "Jonas",
        lastName: "Van Genechten",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      151: {
        id: 40383,
        startno: 151,
        firstName: "Nicolas",
        lastName: "Dalla Valle",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      152: {
        id: 40963,
        startno: 152,
        firstName: "Giovanni",
        lastName: "Lonardi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      153: {
        id: 22010,
        startno: 153,
        firstName: "Mirco",
        lastName: "Maestri",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      154: {
        id: 66440,
        startno: 154,
        firstName: "Fabio",
        lastName: "Mazzucco",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      155: {
        id: 45988,
        startno: 155,
        firstName: "Alessandro",
        lastName: "Monaco",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      157: {
        id: 50033,
        startno: 157,
        firstName: "Samuele",
        lastName: "Zoccarato",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21851,
        teamName: "Bardiani CSF Faizan\u00e8",
      },
      161: {
        id: 865,
        startno: 161,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      162: {
        id: 20822,
        startno: 162,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      163: {
        id: 37996,
        startno: 163,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      164: {
        id: 38000,
        startno: 164,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      165: {
        id: 18574,
        startno: 165,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      166: {
        id: 6519,
        startno: 166,
        firstName: "Sean",
        lastName: "De Bie",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      167: {
        id: 742,
        startno: 167,
        firstName: "Jelle",
        lastName: "Vanendert",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      171: {
        id: 38289,
        startno: 171,
        firstName: "Pierre",
        lastName: "Barbier",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21859,
        teamName: "Delko",
      },
      172: {
        id: 20178,
        startno: 172,
        firstName: "Cl\u00e9ment",
        lastName: "Carisey",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21859,
        teamName: "Delko",
      },
      173: {
        id: 11191,
        startno: 173,
        firstName: "August",
        lastName: "Jensen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21859,
        teamName: "Delko",
      },
      174: {
        id: 6536,
        startno: 174,
        firstName: "Jose",
        lastName: "Goncalves",
        nationCode: "POR",
        nationName: "Portugal",
        teamId: 21859,
        teamName: "Delko",
      },
      175: {
        id: 14778,
        startno: 175,
        firstName: "Eduard Michael",
        lastName: "Grosu",
        nationCode: "ROM",
        nationName: "Romania",
        teamId: 21859,
        teamName: "Delko",
      },
      176: {
        id: 37264,
        startno: 176,
        firstName: "Dusan",
        lastName: "Rajovic",
        nationCode: "SBA",
        nationName: "Serbia",
        teamId: 21859,
        teamName: "Delko",
      },
      177: {
        id: 7755,
        startno: 177,
        firstName: "Evaldas",
        lastName: "Siskevicius",
        nationCode: "LTU",
        nationName: "Lithuania",
        teamId: 21859,
        teamName: "Delko",
      },
      181: {
        id: 33564,
        startno: 181,
        firstName: "Stephen",
        lastName: "Bassett",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      182: {
        id: 37419,
        startno: 182,
        firstName: "Pier-Andre",
        lastName: "Cote",
        nationCode: "CAN",
        nationName: "Canada",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      183: {
        id: 22466,
        startno: 183,
        firstName: "Matteo",
        lastName: "Dal-Cin",
        nationCode: "CAN",
        nationName: "Canada",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      184: {
        id: 9049,
        startno: 184,
        firstName: "Robin",
        lastName: "Carpenter",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      185: {
        id: 16607,
        startno: 185,
        firstName: "Adam",
        lastName: "De Vos",
        nationCode: "CAN",
        nationName: "Canada",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      186: {
        id: 102733,
        startno: 186,
        firstName: "Magnus",
        lastName: "Sheffield",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      187: {
        id: 3332,
        startno: 187,
        firstName: "Emerson",
        lastName: "Oronte",
        nationCode: "USA",
        nationName: "USA",
        teamId: 21861,
        teamName: "Rally Cycling",
      },
      191: {
        id: 32870,
        startno: 191,
        firstName: "Lindsay",
        lastName: "De Vylder",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      192: {
        id: 66276,
        startno: 192,
        firstName: "Gilles",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      193: {
        id: 65033,
        startno: 193,
        firstName: "Arne",
        lastName: "Marit",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      194: {
        id: 10952,
        startno: 194,
        firstName: "Thomas",
        lastName: "Sprengers",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      195: {
        id: 45356,
        startno: 195,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      196: {
        id: 14780,
        startno: 196,
        firstName: "Kenneth",
        lastName: "Van Rooy",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      197: {
        id: 64654,
        startno: 197,
        firstName: "Ward",
        lastName: "Vanhoof",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      201: {
        id: 19254,
        startno: 201,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      202: {
        id: 20104,
        startno: 202,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      203: {
        id: 39175,
        startno: 203,
        firstName: "Alan",
        lastName: "Riou",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      204: {
        id: 63050,
        startno: 204,
        firstName: "Matis",
        lastName: "Louvel",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      205: {
        id: 37495,
        startno: 205,
        firstName: "Bram",
        lastName: "Welten",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      206: {
        id: 13898,
        startno: 206,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      207: {
        id: 77597,
        startno: 207,
        firstName: "Markus",
        lastName: "Pajur",
        nationCode: "EST",
        nationName: "Estonia",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      211: {
        id: 16783,
        startno: 211,
        firstName: "Christopher",
        lastName: "Lawless",
        nationCode: "GBR",
        nationName: "Great Britain",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      212: {
        id: 17183,
        startno: 212,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Cabot",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      213: {
        id: 51269,
        startno: 213,
        firstName: "Valentin",
        lastName: "Ferron",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      214: {
        id: 39819,
        startno: 214,
        firstName: "Marlon",
        lastName: "Gaillard",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      215: {
        id: 330,
        startno: 215,
        firstName: "Damien",
        lastName: "Gaudin",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      216: {
        id: 2326,
        startno: 216,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      217: {
        id: 37800,
        startno: 217,
        firstName: "Florian",
        lastName: "Ma\u00eetre",
        nationCode: "FRA",
        nationName: "France",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      221: {
        id: 27230,
        startno: 221,
        firstName: "Kristoffer",
        lastName: "Halvorsen",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      222: {
        id: 48119,
        startno: 222,
        firstName: "Morten",
        lastName: "Hulgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      223: {
        id: 37403,
        startno: 223,
        firstName: "Niklas",
        lastName: "Larsen",
        nationCode: "DEN",
        nationName: "Denmark",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      224: {
        id: 57958,
        startno: 224,
        firstName: "Erik",
        lastName: "Resell",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      225: {
        id: 27232,
        startno: 225,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      226: {
        id: 37303,
        startno: 226,
        firstName: "Rasmus",
        lastName: "Tiller",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      227: {
        id: 37304,
        startno: 227,
        firstName: "Syver",
        lastName: "W\u00e6rsted",
        nationCode: "NOR",
        nationName: "Norway",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
      },
      231: {
        id: 65341,
        startno: 231,
        firstName: "Andrea",
        lastName: "Bartolozzi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      232: {
        id: 52551,
        startno: 232,
        firstName: "Joab",
        lastName: "Schneiter",
        nationCode: "SUI",
        nationName: "Switzerland",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      233: {
        id: 45996,
        startno: 233,
        firstName: "Mattia",
        lastName: "Bevilacqua",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      234: {
        id: 47431,
        startno: 234,
        firstName: "Alessandro",
        lastName: "Iacchi",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      235: {
        id: 11086,
        startno: 235,
        firstName: "Davide",
        lastName: "Orrico",
        nationCode: "ITA",
        nationName: "Italy",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      236: {
        id: 16892,
        startno: 236,
        firstName: "Wout",
        lastName: "Van Elzakker",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      237: {
        id: 16989,
        startno: 237,
        firstName: "Etienne",
        lastName: "Van Empel",
        nationCode: "NED",
        nationName: "Netherlands",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
      },
      241: {
        id: 38306,
        startno: 241,
        firstName: "Alfdan",
        lastName: "De Decker",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      242: {
        id: 37722,
        startno: 242,
        firstName: "Maxime",
        lastName: "De Poorter",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      243: {
        id: 25505,
        startno: 243,
        firstName: "Ylber",
        lastName: "Sefa",
        nationCode: "ALB",
        nationName: "Albania",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      244: {
        id: 18277,
        startno: 244,
        firstName: "Brent",
        lastName: "Van de Kerkhove",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      245: {
        id: 18558,
        startno: 245,
        firstName: "Elias",
        lastName: "Van Breussegem",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      246: {
        id: 27336,
        startno: 246,
        firstName: "Julien",
        lastName: "Van Den Brande",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
      247: {
        id: 37220,
        startno: 247,
        firstName: "Enzo",
        lastName: "Wouters",
        nationCode: "BEL",
        nationName: "Belgium",
        teamId: 23305,
        teamName: "Tarteletto - Isorex",
      },
    },
  }
  const race = "Nokere Koerse"
  const raceID = 122

  return (
    <RacepageFS
      title="Nokere Koerse 2021"
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default NokereKoerse2021
